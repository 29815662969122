const getPath = (uid: string, slug: string, locale: string) => {
  if (!uid) { return null }

  const routeObject = baseRouteGetObject(
    getStrapiRouteReference(uid)?.name,
    slug || null
  )

  const runtimeConfig = useRuntimeConfig()
  const baseUrl = runtimeConfig.baseUrl || runtimeConfig.public.baseUrl

  const nuxtApp = useNuxtApp()
  const path = nuxtApp.$localePath(routeObject, locale)

  if (isValidURL(baseUrl)) { return null }

  return new URL(path, baseUrl).href
}

const isValidURL = (string: string) => {
  const res = string.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_.~#?&//=]*)/g)
  return (res !== null)
}

export const getMetaObject = (data: object, image?: object) => {
  if (!data) { return null }
  if ((data instanceof Object) === false) { return null }

  const mainStore = useMainStore()

  const seo = data?.seo

  const localizations = toRaw(data?.localizations?.data)
    ?.map((localization: any) => localization?.data)

  const nuxtApp = useNuxtApp()
  const { $i18n } = nuxtApp

  const title = seo?.title || data?.title
  const siteTitle = mainStore.siteTitle

  const ogImage = image || data?.image

  return {
    description: seo?.description,
    imageUrl: ogImage ? getAbsoluteFileUrl(ogImage?.url, ogImage?.provider) : null,
    keywords: seo?.keywords,
    locale: data?.locale,
    robots: seo?.robots?.join(', '),
    siteTitle: siteTitle,
    title: title !== siteTitle ? title : null,
    url: getPath(data?.__typename, data?.slug),
    localizations: localizations
      ? localizations?.map((localization: any) => {
        return {
          locale: localization?.locale,
          url: getPath(data?.__typename, localization?.slug, baseI18nGetCodeForIso(localization?.locale))
        }
      })
      : null
  }
}
