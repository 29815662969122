import { omitBy, isNil } from 'lodash-es'

export const getSeoMetaObject = (data: object) => {
  return omitBy({
    contentType: 'text/html; charset=utf-8',
    charset: 'utf-8',
    viewport: 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0',
    robots: data?.robots || 'index, follow',
    keywords: data?.keywords,
    description: data?.description,

    ogType: 'website',
    ogUrl: data?.url,
    ogSiteName: data?.siteTitle,
    ogTitle: data?.title !== data?.siteTitle ? data?.title : null,
    ogDescription: data?.description,
    ogImage: data?.imageUrl,
    ogLocale: data?.locale,

    twitterCard: 'summary',
    twitterTitle: data?.title,
    twitterDescription: data?.description,
    twitterImage: data?.imageUr
  }, isNil)
}
