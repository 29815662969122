const setI18nRouteMeta = (data: any, image?: string) => {
  if (!data) { return }
  if (!data?.localizations) return

  const setI18nParams = useSetI18nParams()
  const nuxtApp = useNuxtApp()
  const { locales, defaultLocale } = nuxtApp.$i18n

  const localeCodeForIso = (iso?: string) => {
    if (!iso) { return }
    return locales.value?.find(i => i.iso === iso)?.code
  }

  let i18nMetaObject = data?.slug ? {
    [defaultLocale]: { slug: data?.slug }
  } : {}

  const localizations = data?.localizations || null

  if (!localizations) { return }

  localizations?.forEach((i: any) => {
    if (i?.slug) {
      i18nMetaObject[localeCodeForIso(i?.locale)] = { slug: i?.slug }
    }
  })

  // FIXME
  if (i18nMetaObject && Object.keys(i18nMetaObject).length > 0) {
    setI18nParams(i18nMetaObject)
  }
}

export const setHead = (data: object, image?: object) => {
  const mainStore = useMainStore()

  useHead({
    titleTemplate: (titleChunk) => {
      if (titleChunk === mainStore.siteTitle) {
        return mainStore.siteTitle
      } else if (titleChunk) {
        return `${titleChunk} – ${mainStore.siteTitle}`
      } else {
        return mainStore.siteTitle || 'Manifesta'
      }
    },
    title: data?.title,
    htmlAttrs: [
      { lang: baseI18nGetActiveIso() },
      { dir: baseI18nGetActiveDir() }
    ]
  })

  useSeoMeta(
    getSeoMetaObject(getMetaObject(data, image))
  )

  setI18nRouteMeta(data)
}
